<template>
  <div class="metrics-wheels" :key="key">
    <v-card outlined rounded="lg" class="pa-6 pb-8">
      <div class="f18 font-weight-bold">Total wheels</div>
      <div class="d-flex align-center">
        <div class="font-weight-bold f36">{{ counters.posts_total }}</div>
        <div class="status ml-4 success lighten-5 success--text" v-if="counters.posts_new">
          <v-icon color="success" class="mr-1">mdi-arrow-top-right</v-icon> {{ counters.posts_new }} wheels today
        </div>
      </div>
    </v-card>
    <v-card outlined rounded="lg" class="pa-4 mt-4 growth">
      <div class="f18 font-weight-bold mb-3">Wheels growth</div>
      <v-chartist type="Line" :data="{ labels: growth.labels, series: [growth.series] }" :options="chartOptions"></v-chartist>
    </v-card>
    <v-card outlined rounded="lg" class="pa-4 mt-4 new-wheels">
      <div class="f18 font-weight-bold mb-3">New wheels</div>
      <v-chartist type="Bar" :data="wheels" :options="chartOptions"></v-chartist>
    </v-card>
    <v-card outlined rounded="lg" class="pa-4 mt-4 growth-rate">
      <div class="f18 font-weight-bold mb-3">Wheels growth rate</div>
      <v-chartist type="Bar" :data="growthRate" :options="chartOptions"></v-chartist>
    </v-card>
  </div>
</template>

<script>
import VueChartist from 'v-chartist';

export default {
  components: {
    'v-chartist': VueChartist,
  },
  props: {
    period: String,
    date: Object,
  },
  data() {
    return {
      key: 1,
      chartOptions: {
        height: '280px',
        fullWidth: true,
        distributeSeries: true,
        lineSmooth: false,
        showPoint: false,
        chartPadding: {
          right: 30,
        },
        axisX: {
          showGrid: false,
        },
      },
      growth: { series: [] },
      wheels: {},
      growthRate: {},
      typeList: ['posts_total', 'posts_new', 'posts_growth'],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.$store.dispatch('getMetricPageCounter', { page: 'posts', type: 'wheels' });
      for (let i = 0; i < this.typeList.length; i++) {
        this.$store
          .dispatch('getMetricPageStatistics', {
            page: 'wheels',
            period: this.period,
            from: this.date.start,
            to: this.date.end,
            type: this.typeList[i],
          })
          .then((res) => {
            if (i == 0) {
              this.growth = res.data;
            } else if (i == 1) {
              this.wheels = res.data;
            } else if (i == 2) {
              this.growthRate = res.data;
            }
          });
      }
    },
  },
  computed: {
    counters() {
      return this.$store.getters.metricPageCounter;
    },
  },
};
</script>

<style lang="scss">
.metrics-wheels {
  .status {
    display: flex;
    align-items: center;
    font-weight: 500;
    padding: 5px 16px 6px 10px;
    border-radius: 16px;
  }
  .growth-rate,
  .growth,
  .new-wheels {
    .ct-bar,
    .ct-point,
    .ct-line {
      stroke: var(--v-primary-base);
    }
  }
  .growth-rate {
    .ct-bar {
      stroke: var(--v-info-base);
    }
  }
  .growth-rate,
  .new-wheels {
    .ct-bar {
      stroke-width: 20px;
    }
  }
}
</style>
