<template>
  <div class="metrics-topics" :key="key">
    <v-card outlined rounded="lg" class="pa-6 pb-8">
      <div class="f18 font-weight-bold">Total topics</div>
      <div class="d-flex align-center">
        <div class="font-weight-bold f36">{{ counters.total }}</div>
        <div class="status ml-4 success lighten-5 success--text" v-if="counters.new">
          <v-icon color="success" class="mr-1">mdi-arrow-top-right</v-icon> {{ counters.new }} topics today
        </div>
      </div>
    </v-card>
    <v-data-table class="mt-4" hide-default-footer fixed-header :headers="headers" :items="data" item-key="id" :items-per-page="-1">
      <template v-slot:no-data v-if="!data.length">
        <div class="d-flex flex-column align-center justify-center height100 text-center" style="height: 300px">
          <div style="max-width: 370px" class="f18 black--text font-weight-bold mb-6">Sorry, no data</div>
        </div>
      </template>
      <template v-slot:body="{ items }" v-else>
        <tbody>
          <tr v-for="(item, i) in items" :key="item.id" height="56">
            <td>{{ i + 1 }}</td>
            <td>
              <div class="d-flex align-center">
                <v-img v-if="item.icon" class="mr-2" height="24" contain max-width="24" :src="item.icon" alt="logo"></v-img>{{ item.title }}
              </div>
            </td>
            <td>{{ item.category ? item.category.title : '' }}</td>
            <td>{{ item.used_count_posts > 1000 ? `${item.used_count_posts / 1000}k` : item.used_count_posts }}</td>
            <td>{{ item.used_count_wheels > 1000 ? `${item.used_count_wheels / 1000}k` : item.used_count_wheels }}</td>
            <td>{{ item.used_count_questions > 1000 ? `${item.used_count_questions / 1000}k` : item.used_count_questions }}</td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: {
    period: String,
    date: Object,
  },
  data() {
    return {
      key: 1,
      data: [],
      headers: [
        { text: '#', value: 'i', sortable: false, class: 'width0' },
        { text: 'Topic', value: 'title', sortable: false },
        { text: 'Category', value: 'category.title', sortable: false },
        { text: 'Using: Posts', value: 'used_count_posts', sortable: false },
        { text: 'Using: Wheels', value: 'used_count_wheels', sortable: false },
        { text: 'Using: Questions', value: 'used_count_questions', sortable: false },
      ],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.$store.dispatch('getMetricPageCounter', { page: 'topics' });
      this.$store
        .dispatch('getMetricPageStatistics', {
          page: 'topics',
          period: this.period,
          from: this.date.start,
          to: this.date.end,
          type: 'tranding',
        })
        .then((res) => {
          this.data = res.data;
        });
    },
  },
  computed: {
    counters() {
      return this.$store.getters.metricPageCounter;
    },
  },
};
</script>

<style lang="scss">
.metrics-topics {
  .status {
    display: flex;
    align-items: center;
    font-weight: 500;
    padding: 5px 16px 6px 10px;
    border-radius: 16px;
  }
}
</style>
