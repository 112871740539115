<template>
  <div class="metrics-questions" :key="key">
    <v-card outlined rounded="lg" class="pa-6 pb-7 mr-2 width100">
      <div class="f18 font-weight-bold">Total questions</div>
      <div class="d-flex align-center">
        <div class="font-weight-bold f36">{{ counters.questions_total }}</div>
        <div class="status ml-4 success lighten-5 success--text" v-if="counters.questions_new">
          <v-icon color="success" class="mr-1">mdi-arrow-top-right</v-icon> {{ counters.questions_new }} questions today
        </div>
      </div>
    </v-card>
    <div class="d-flex align-stretch">
      <div class="mr-2 width100">
        <v-card outlined rounded="lg" class="pa-6 width100 mt-4" min-height="177">
          <div class="f18 font-weight-bold">Total answers</div>
          <div class="font-weight-bold f36">{{ counters.answers_total }}</div>
          <div class="status success lighten-5 success--text mt-4" v-if="counters.answers_new">
            <v-icon color="success" class="mr-1">mdi-arrow-top-right</v-icon> {{ counters.answers_new }} answers today
          </div>
        </v-card>
        <v-card outlined rounded="lg" class="pa-6 width100 mt-4" min-height="177">
          <div class="f18 font-weight-bold">Total comments</div>
          <div class="font-weight-bold f36">{{ counters.comments_total }}</div>
          <div class="status success lighten-5 success--text mt-4" v-if="counters.comments_new">
            <v-icon color="success" class="mr-1">mdi-arrow-top-right</v-icon> {{ counters.comments_new }} comments today
          </div>
        </v-card>
      </div>
      <v-card outlined rounded="lg" class="pa-6 width100 mt-4 ml-2 pie">
        <div class="f18 font-weight-bold">Questions</div>
        <v-chartist type="Pie" :data="{ series: seriesPie }" :options="chartOptionsPie"></v-chartist>
        <v-card class="rounded-circle d-flex align-center justify-center flex-column circle" width="98" height="98">
          <div class="font-weight-bold text-h5">{{ ((counters.questions_answered * 100) / counters.questions_total).toFixed(2) }}%</div>
          <div class="text-caption gray--text text--lighten-1">Answered</div>
        </v-card>
        <div class="d-flex align-center justify-space-around mt-6">
          <div class="d-flex align-center">
            <v-card flat class="cyan" width="12" height="12"></v-card>
            <div class="f15 text-600 gray--text ml-2">Answered</div>
          </div>
          <div class="d-flex align-center">
            <v-card flat class="gray lighten-3" width="12" height="12"></v-card>
            <div class="f15 text-600 gray--text ml-2">Unanswered</div>
          </div>
        </div>
      </v-card>
    </div>
    <v-card outlined rounded="lg" class="pa-4 mt-4 questions">
      <div class="f18 font-weight-bold mb-3">Questions growth</div>
      <v-chartist type="Line" :data="{ labels: questions.labels, series: [questions.series] }" :options="chartOptions"></v-chartist>
    </v-card>
    <v-card outlined rounded="lg" class="pa-4 mt-4 new-questions">
      <div class="f18 font-weight-bold mb-3">New questions</div>
      <v-chartist type="Bar" :data="newQuestions" :options="chartOptions"></v-chartist>
    </v-card>
    <v-card outlined rounded="lg" class="pa-4 mt-4 growth">
      <div class="f18 font-weight-bold mb-3">Questions growth rate</div>
      <v-chartist type="Bar" :data="growth" :options="chartOptions"></v-chartist>
    </v-card>
    <v-card outlined rounded="lg" class="pa-4 mt-4 answers">
      <div class="f18 font-weight-bold mb-3">Answers growth</div>
      <v-chartist type="Line" :data="{ labels: answers.labels, series: [answers.series] }" :options="chartOptions"></v-chartist>
    </v-card>
    <v-card outlined rounded="lg" class="pa-4 mt-4 new-answers">
      <div class="f18 font-weight-bold mb-3">New answers</div>
      <v-chartist type="Bar" :data="newAnswers" :options="chartOptions"></v-chartist>
    </v-card>
    <v-card outlined rounded="lg" class="pa-4 mt-4 comment">
      <div class="f18 font-weight-bold mb-3">Answer comments growth</div>
      <v-chartist type="Line" :data="{ labels: comment.labels, series: [comment.series] }" :options="chartOptions"></v-chartist>
    </v-card>
    <v-card outlined rounded="lg" class="pa-4 mt-4 new-comment">
      <div class="f18 font-weight-bold mb-3">New answer comments</div>
      <v-chartist type="Bar" :data="newComment" :options="chartOptions"></v-chartist>
    </v-card>
  </div>
</template>

<script>
import VueChartist from 'v-chartist';

export default {
  components: {
    'v-chartist': VueChartist,
  },
  props: {
    period: String,
    date: Object,
  },
  data() {
    return {
      key: 1,
      chartOptions: {
        height: '280px',
        fullWidth: true,
        distributeSeries: true,
        lineSmooth: false,
        showPoint: false,
        chartPadding: {
          right: 30,
        },
        axisX: {
          showGrid: false,
        },
      },
      chartOptionsPie: {
        height: '240px',
        donut: true,
        donutWidth: 35,
        donutSolid: true,
        startAngle: 0,
        showLabel: false,
      },
      questions: { series: [] },
      newQuestions: {},
      answers: { series: [] },
      newAnswers: {},
      comment: { series: [] },
      newComment: {},
      growth: {},
      seriesPie: [],
      typeList: ['questions_total', 'questions_new', 'answers_total', 'answers_new', 'comments_total', 'comments_new', 'questions_growth'],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.$store.dispatch('getMetricPageCounter', { page: 'questions' });
      for (let i = 0; i < this.typeList.length; i++) {
        await this.$store
          .dispatch('getMetricPageStatistics', {
            page: 'questions',
            period: this.period,
            from: this.date.start,
            to: this.date.end,
            type: this.typeList[i],
          })
          .then((res) => {
            if (i == 0) {
              this.questions = res.data;
            } else if (i == 1) {
              this.newQuestions = res.data;
            } else if (i == 2) {
              this.answers = res.data;
            } else if (i == 3) {
              this.newAnswers = res.data;
            } else if (i == 4) {
              this.comment = res.data;
            } else if (i == 5) {
              this.newComment = res.data;
            } else if (i == 6) {
              this.growth = res.data;
            }
          });
      }
    },
  },
  watch: {
    counters(val) {
      this.seriesPie = [];
      if (val) {
        this.seriesPie.push(val.questions_answered);
        this.seriesPie.push(val.questions_unanswered);
      }
    },
  },
  computed: {
    counters() {
      return this.$store.getters.metricPageCounter;
    },
  },
};
</script>

<style lang="scss">
.metrics-questions {
  .status {
    width: max-content;
    display: flex;
    align-items: center;
    font-weight: 500;
    padding: 5px 16px 6px 10px;
    border-radius: 16px;
  }
  .questions,
  .new-questions {
    .ct-bar,
    .ct-point,
    .ct-line {
      stroke: var(--v-primary-base);
    }
  }
  .answers,
  .new-answers {
    .ct-bar,
    .ct-point,
    .ct-line {
      stroke: var(--v-cyan-base);
    }
  }
  .comment,
  .new-comment {
    .ct-bar,
    .ct-point,
    .ct-line {
      stroke: var(--v-warning-base);
    }
  }
  .growth {
    .ct-bar {
      stroke: var(--v-info-base);
    }
  }
  .growth,
  .new-questions,
  .new-answers,
  .new-comment {
    .ct-bar {
      stroke-width: 20px;
    }
  }
  .pie {
    position: relative;
    .circle {
      position: absolute;
      top: 122px;
      left: 0;
      right: 0;
      margin: 0 auto;
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.19) !important;
    }
    .circle::before {
      content: '';
      position: absolute;
      background: transparent;
      border-radius: 9999px;
      display: block;
      width: 130px;
      min-height: 130px;
      height: 130px;
      border: 1px dashed var(--v-gray-lighten2);
    }
    .ct-series-a .ct-slice-donut-solid {
      fill: var(--v-cyan-base);
    }
    .ct-series-b .ct-slice-donut-solid {
      fill: var(--v-gray-lighten3);
    }
  }
}
</style>
