<template>
  <div class="metrics-searches" :key="key">
    <v-card outlined rounded="lg" class="pa-6 pb-8">
      <div class="f18 font-weight-bold">Unique search phrases</div>
      <div class="d-flex align-center">
        <div class="font-weight-bold f36">{{ counters.total }}</div>
        <div class="status ml-4 success lighten-5 success--text" v-if="counters.new">
          <v-icon color="success" class="mr-1">mdi-arrow-top-right</v-icon> {{ counters.new }} search today
        </div>
      </div>
    </v-card>
    <v-data-table
      class="mt-4"
      hide-default-footer
      fixed-header
      :headers="headers"
      :items="data"
      item-key="id"
      :items-per-page="-1"
    >
      <template v-slot:no-data v-if="!data.length">
        <div class="d-flex flex-column align-center justify-center height100 text-center" style="height: 300px">
          <div style="max-width: 370px" class="f18 black--text font-weight-bold mb-6">Sorry, no data</div>
        </div>
      </template>
      <template v-slot:body="{ items }" v-else>
        <tbody>
          <tr v-for="(item, i) in items" :key="item.id" height="56">
            <td>{{ i + 1 }}</td>
            <td>{{ item.phrase }}</td>
            <td class="text-end">{{ item.used > 1000 ? `${item.used / 1000}k` : item.used }}</td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: {
    period: String,
    date: Object,
  },
  data() {
    return {
      key: 1,
      data: [],
      headers: [
        { text: '#', value: 'i', sortable: false, class: 'width0' },
        { text: 'Search phrase', value: 'phrase', sortable: false },
        { text: 'Using', value: 'used', sortable: false, class: 'text-end' },
      ],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.$store.dispatch('getMetricPageCounter', { page: 'searches' });
      this.$store
        .dispatch('getMetricPageStatistics', {
          page: 'searches',
          period: this.period,
          from: this.date.start,
          to: this.date.end,
          type: 'tranding',
        })
        .then((res) => {
          this.data = res.data;
        });
    },
  },
  computed: {
    counters() {
      return this.$store.getters.metricPageCounter;
    },
  },
};
</script>

<style lang="scss">
.metrics-searches {
  .status {
    display: flex;
    align-items: center;
    font-weight: 500;
    padding: 5px 16px 6px 10px;
    border-radius: 16px;
  }
}
</style>
