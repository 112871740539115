<template>
  <div class="metrics-posts" :key="key">
    <div class="d-flex align-center">
      <v-card outlined rounded="lg" class="pa-6 pb-8 mr-2 width100">
        <div class="f18 font-weight-bold">Total posts</div>
        <div class="d-flex align-center">
          <div class="font-weight-bold f36">{{ counters.posts_total }}</div>
          <div class="status ml-4 success lighten-5 success--text" v-if="counters.posts_new">
            <v-icon color="success" class="mr-1">mdi-arrow-top-right</v-icon> {{ counters.posts_new }} posts today
          </div>
        </div>
      </v-card>
      <v-card outlined rounded="lg" class="pa-6 pb-8 ml-2 width100">
        <div class="f18 font-weight-bold">Total comments</div>
        <div class="d-flex align-center">
          <div class="font-weight-bold f36">{{ counters.comments_total }}</div>
          <div class="status ml-4 success lighten-5 success--text" v-if="counters.comments_new">
            <v-icon color="success" class="mr-1">mdi-arrow-top-right</v-icon> {{ counters.comments_new }} comments today
          </div>
        </div>
      </v-card>
    </div>
    <v-card outlined rounded="lg" class="pa-4 mt-4 growth">
      <div class="f18 font-weight-bold mb-3">Posts growth</div>
      <v-chartist type="Line" :data="{ labels: growth.labels, series: [growth.series] }" :options="chartOptions"></v-chartist>
    </v-card>
    <v-card outlined rounded="lg" class="pa-4 mt-4 new-posts">
      <div class="f18 font-weight-bold mb-3">New posts</div>
      <v-chartist type="Bar" :data="newPosts" :options="chartOptions"></v-chartist>
    </v-card>
    <v-card outlined rounded="lg" class="pa-4 mt-4 regular">
      <div class="f18 font-weight-bold mb-3">Posts growth rate</div>
      <v-chartist type="Bar" :data="regular" :options="chartOptions"></v-chartist>
    </v-card>
    <v-card outlined rounded="lg" class="pa-4 mt-4 comment">
      <div class="f18 font-weight-bold mb-3">Post comments</div>
      <v-chartist type="Line" :data="{ labels: comment.labels, series: [comment.series] }" :options="chartOptions"></v-chartist>
    </v-card>
    <v-card outlined rounded="lg" class="pa-4 mt-4 new-comment">
      <div class="f18 font-weight-bold mb-3">New post comments</div>
      <v-chartist type="Bar" :data="newComment" :options="chartOptions"></v-chartist>
    </v-card>
  </div>
</template>

<script>
import VueChartist from 'v-chartist';

export default {
  components: {
    'v-chartist': VueChartist,
  },
  props: {
    period: String,
    date: Object,
  },
  data() {
    return {
      key: 1,
      chartOptions: {
        height: '280px',
        fullWidth: true,
        distributeSeries: true,
        lineSmooth: false,
        showPoint: false,
        chartPadding: {
          right: 30,
        },
        axisX: {
          showGrid: false,
        },
      },
      growth: { series: [] },
      newPosts: {},
      comment: { series: [] },
      newComment: {},
      regular: {},
      typeList: ['posts_total', 'posts_new', 'comments_total', 'comments_new', 'posts_growth'],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.$store.dispatch('getMetricPageCounter', { page: 'posts', type: 'regular' });
      for (let i = 0; i < this.typeList.length; i++) {
        this.$store
          .dispatch('getMetricPageStatistics', {
            page: 'posts',
            period: this.period,
            from: this.date.start,
            to: this.date.end,
            type: this.typeList[i],
          })
          .then((res) => {
            if (i == 0) {
              this.growth = res.data;
            } else if (i == 1) {
              this.newPosts = res.data;
            } else if (i == 2) {
              this.comment = res.data;
            } else if (i == 3) {
              this.newComment = res.data;
            } else if (i == 4) {
              this.regular = res.data;
            }
          });
      }
    },
  },
  computed: {
    counters() {
      return this.$store.getters.metricPageCounter;
    },
  },
};
</script>

<style lang="scss">
.metrics-posts {
  .status {
    display: flex;
    align-items: center;
    font-weight: 500;
    padding: 5px 16px 6px 10px;
    border-radius: 16px;
  }
  .growth,
  .new-posts {
    .ct-bar,
    .ct-point,
    .ct-line {
      stroke: var(--v-primary-base);
    }
  }
  .comment,
  .new-comment {
    .ct-bar,
    .ct-point,
    .ct-line {
      stroke: var(--v-cyan-base);
    }
  }
  .regular {
    .ct-bar {
      stroke: var(--v-info-base);
    }
  }
  .regular,
  .new-posts,
  .new-comment {
    .ct-bar {
      stroke-width: 20px;
    }
  }
}
</style>
