<template>
  <div class="metrics">
    <div class="text-sm-h5 text-h6 font-weight-bold">Metrics</div>
    <div class="mt-5 d-flex">
      <v-card flat tile color="transparent" class="mr-3" min-width="280" width="280">
        <v-card rounded="lg" outlined class="pa-4 pr-8">
          <div class="f15 text-600 gray--text text--lighten-1 mb-3">Metrics</div>
          <v-list class="py-0">
            <v-list-item
              @click="menuItem == i ? '' : selectMenu(i)"
              v-for="i in menuList"
              :key="i"
              class="rounded text-600 text-capitalize"
              :class="menuItem == i ? 'gray lighten-2' : ''"
            >
              {{ i }}
            </v-list-item>
          </v-list>
        </v-card>
        <v-card rounded="lg" outlined class="pa-4 pr-8 mt-4">
          <div class="f15 text-600 gray--text text--lighten-1 mb-3">Period</div>
          <v-list class="py-0">
            <v-list-item
              @click="periodItem == i.id ? '' : selectPeriod(i.id)"
              v-for="i in periodList"
              :key="i.id"
              class="rounded text-600"
              :class="periodItem == i.id ? 'gray lighten-2' : ''"
            >
              {{ i.name }}
            </v-list-item>
            <v-list-item
              @click="periodItem == 'ever' ? '' : selectPeriod('ever')"
              class="rounded text-600"
              :class="periodItem == 'ever' ? 'gray lighten-2' : ''"
              v-if="menuItem == 'topics' || menuItem == 'hashtags' || menuItem == 'searches'"
            >
              Ever
            </v-list-item>
            <v-list-item
              @click="periodItem == 'custom' ? '' : selectPeriod('custom')"
              class="rounded text-600 d-flex align-center"
              :class="periodItem == 'custom' ? 'gray lighten-2' : ''"
            >
              Custom period
              <v-spacer></v-spacer>
              <v-icon color="gray lighten-1">
                {{ periodItem == 'custom' ? 'mdi-chevron-down' : 'mdi-chevron-right' }}
              </v-icon>
            </v-list-item>
          </v-list>
          <div v-if="periodItem == 'custom'" class="mt-5" :key="key">
            <v-menu offset-y :close-on-content-click="closeMenu">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  class="field48"
                  :value="`${date.start ? new Date(date.start).toLocaleString().substr(0, 10) : 'From'}`"
                  v-bind="attrs"
                  v-on="on"
                  @click="closeMenu = false"
                  placeholder="From"
                  outlined
                  dense
                  required
                  color="primary"
                  height="48"
                >
                  <template v-slot:append>
                    <v-icon color="gray lighten-1">mdi-calendar-blank-outline</v-icon>
                  </template>
                </v-text-field>
                <v-text-field
                  class="field48 mt-n4"
                  :value="`${date.end ? `${new Date(date.end).toLocaleString().substr(0, 10)}` : 'To'}`"
                  v-bind="attrs"
                  v-on="on"
                  placeholder="To"
                  outlined
                  dense
                  required
                  color="primary"
                  height="48"
                >
                  <template v-slot:append>
                    <v-icon color="gray lighten-1">mdi-calendar-blank-outline</v-icon>
                  </template>
                </v-text-field>
              </template>
              <v-card flat color="white">
                <vc-date-picker
                  style="border: initial"
                  :columns="$screens({ default: 1, lg: 2 })"
                  locale="en"
                  :input-debounce="500"
                  v-model="date"
                  :masks="masks"
                  :model-config="modelConfig"
                  :first-day-of-week="2"
                  is-range
                  color="blue"
                ></vc-date-picker>
                <div class="text-end pr-3 pb-3">
                  <v-btn @click="closeMenu = true" depressed color="primary" class="rounded mr-3" width="96" height="30">
                    <span class="text-body-2">Select</span>
                  </v-btn>
                  <v-btn @click="closeCalendar" outlined depressed color="gray" class="rounded" width="96" height="30">
                    <span class="text-body-2 text--text">Cancel</span>
                  </v-btn>
                </div>
              </v-card>
            </v-menu>
            <v-btn @click="selectPeriod('custom')" class="mt-n2" block height="48" color="primary" depressed>
              <span class="f15 text-600">Confirm</span>
            </v-btn>
          </div>
        </v-card>
      </v-card>
      <div class="ml-3 width100" :key="key">
        <Users v-if="menuItem == 'users'" :period="periodItem" :date="date" />
        <Cars v-if="menuItem == 'cars'" :period="periodItem" :date="date" />
        <Posts v-if="menuItem == 'posts'" :period="periodItem" :date="date" />
        <Wheels v-if="menuItem == 'wheels'" :period="periodItem" :date="date" />
        <Questions v-if="menuItem == 'questions'" :period="periodItem" :date="date" />
        <Conversations v-if="menuItem == 'conversations'" :period="periodItem" :date="date" />
        <Topics v-if="menuItem == 'topics'" :period="periodItem" :date="date" />
        <Hashtags v-if="menuItem == 'hashtags'" :period="periodItem" :date="date" />
        <Searches v-if="menuItem == 'searches'" :period="periodItem" :date="date" />
      </div>
    </div>
  </div>
</template>

<script>
import Users from '@/components/metrics/Users';
import Cars from '@/components/metrics/Cars';
import Posts from '@/components/metrics/Posts';
import Wheels from '@/components/metrics/Wheels';
import Questions from '@/components/metrics/Questions';
import Conversations from '@/components/metrics/Conversations';
import Topics from '@/components/metrics/Topics';
import Hashtags from '@/components/metrics/Hashtags';
import Searches from '@/components/metrics/Searches';

export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  components: {
    Users,
    Cars,
    Posts,
    Wheels,
    Questions,
    Conversations,
    Topics,
    Hashtags,
    Searches,
  },
  data() {
    return {
      closeMenu: false,
      key: 1,
      menuItem: this.$route.query.type || 'users',
      periodItem: this.$route.query.period || '1w',
      date: {
        start: this.$route.query.period_from || '',
        end: this.$route.query.period_to || '',
      },
      masks: {
        input: 'DD.MM.YYYY',
      },
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      menuList: ['users', 'cars', 'posts', 'wheels', 'questions', 'conversations', 'topics', 'hashtags', 'searches'],
      periodList: [
        { id: '1w', name: 'This week' },
        { id: '1m', name: 'This month' },
        { id: '1q', name: 'This quarter' },
        { id: '1y', name: 'This year' },
      ],
    };
  },
  methods: {
    selectMenu(i) {
      if (this.periodItem == 'ever' && ['users', 'cars', 'posts', 'wheels', 'questions', 'conversations'].some((j) => j == i)) {
        this.periodItem = '1y';
      }
      this.menuItem = i;
      window.history.pushState(
        {},
        '',
        `${this.$route.path}?type=${this.menuItem}&period=${this.periodItem}${
          this.periodItem == 'custom' ? `&period_from=${this.date.start}&period_to=${this.date.end}}` : ''
        }`
      );
      ++this.key;
      // this.getData();
    },
    selectPeriod(i) {
      this.periodItem = i;
      window.history.pushState(
        {},
        '',
        `${this.$route.path}?type=${this.menuItem}&period=${this.periodItem}${
          this.periodItem == 'custom' ? `&period_from=${this.date.start}&period_to=${this.date.end}}` : ''
        }`
      );
      ++this.key;
      // this.getData();
    },
    closeCalendar() {
      this.closeMenu = true;
      this.date.start = '';
      this.date.end = '';
      ++this.key;
      // this.getData();
    },
  },
};
</script>

<style lang="scss">
.metrics {
  .field48 .v-input__append-inner {
    margin-top: 14px !important;
  }
}
</style>
