<template>
  <div class="metrics-users" :key="key">
    <v-card outlined rounded="lg" class="pa-6 pb-8">
      <div class="f18 font-weight-bold">Total users</div>
      <div class="d-flex align-center">
        <div class="font-weight-bold f36">{{ counters.total }}</div>
        <div class="status ml-4 success lighten-5 success--text" v-if="counters.new">
          <v-icon color="success" class="mr-1">mdi-arrow-top-right</v-icon> {{ counters.new }} users today
        </div>
      </div>
    </v-card>
    <v-card outlined rounded="lg" class="pa-4 mt-4 total">
      <div class="f18 font-weight-bold mb-3">Total users</div>
      <v-chartist type="Line" :data="{ labels: total.labels, series: [total.series] }" :options="chartOptions"></v-chartist>
    </v-card>
    <v-card outlined rounded="lg" class="pa-4 mt-4 reg">
      <div class="f18 font-weight-bold mb-3">Users registration</div>
      <v-chartist type="Bar" :data="reg" :options="chartOptions"></v-chartist>
    </v-card>
    <v-card outlined rounded="lg" class="pa-4 mt-4 growth">
      <div class="f18 font-weight-bold mb-3">User growth rate</div>
      <v-chartist type="Bar" :data="growth" :options="chartOptions"></v-chartist>
    </v-card>
    <v-card outlined rounded="lg" class="pa-4 mt-4 retention">
      <div class="f18 font-weight-bold mb-3">User retention rate</div>
      <v-chartist type="Bar" :data="retention" :options="chartOptions"></v-chartist>
    </v-card>
    <v-card outlined rounded="lg" class="pa-4 mt-4 churn">
      <div class="f18 font-weight-bold mb-3">User churn rate</div>
      <v-chartist type="Bar" :data="churn" :options="chartOptions"></v-chartist>
    </v-card>
    <v-card outlined rounded="lg" class="pa-4 mt-4 blocked">
      <div class="f18 font-weight-bold mb-3">Users blocked</div>
      <v-chartist type="Bar" :data="blocked" :options="chartOptions"></v-chartist>
    </v-card>
    <v-card outlined rounded="lg" class="pa-4 mt-4 removed">
      <div class="f18 font-weight-bold mb-3">Users removed</div>
      <v-chartist type="Bar" :data="removed" :options="chartOptions"></v-chartist>
    </v-card>
  </div>
</template>

<script>
import VueChartist from 'v-chartist';

export default {
  components: {
    'v-chartist': VueChartist,
  },
  props: {
    period: String,
    date: Object,
  },
  data() {
    return {
      key: 1,
      chartOptions: {
        height: '280px',
        fullWidth: true,
        distributeSeries: true,
        lineSmooth: false,
        showPoint: false,
        chartPadding: {
          right: 30,
        },
        axisX: {
          showGrid: false,
        },
      },
      total: { series: [] },
      reg: {},
      blocked: {},
      removed: {},
      growth: {},
      retention: {},
      churn: {},
      typeList: ['total', 'new', 'blocked', 'removed', 'growth', 'retention', 'churn'],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.$store.dispatch('getMetricPageCounter', { page: 'users' });
      for (let i = 0; i < this.typeList.length; i++) {
        this.$store
          .dispatch('getMetricPageStatistics', {
            page: 'users',
            period: this.period,
            from: this.date.start,
            to: this.date.end,
            type: this.typeList[i],
          })
          .then((res) => {
            if (i == 0) {
              this.total = res.data;
            } else if (i == 1) {
              this.reg = res.data;
            } else if (i == 2) {
              this.blocked = res.data;
            } else if (i == 3) {
              this.removed = res.data;
            } else if (i == 4) {
              this.growth = res.data;
            } else if (i == 5) {
              this.retention = res.data;
            } else if (i == 6) {
              this.churn = res.data;
            }
          });
      }
    },
  },
  computed: {
    counters() {
      return this.$store.getters.metricPageCounter;
    },
  },
};
</script>

<style lang="scss">
.metrics-users {
  .status {
    display: flex;
    align-items: center;
    font-weight: 500;
    padding: 5px 16px 6px 10px;
    border-radius: 16px;
  }
  .reg,
  .total {
    .ct-bar,
    .ct-point,
    .ct-line {
      stroke: var(--v-primary-base);
    }
  }
  .blocked {
    .ct-bar {
      stroke: var(--v-warning-base);
    }
  }
  .removed {
    .ct-bar {
      stroke: var(--v-gray-base);
    }
  }
  .growth {
    .ct-bar {
      stroke: var(--v-info-base);
    }
  }
  .retention {
    .ct-bar {
      stroke: var(--v-cyan-base);
    }
  }
  .churn {
    .ct-bar {
      stroke: var(--v-error-base);
    }
  }
  .reg,
  .blocked,
  .growth,
  .retention,
  .churn,
  .removed {
    .ct-bar {
      stroke-width: 20px;
    }
  }
}
</style>
